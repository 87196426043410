export const introScreens = [
    {
        id: '1',
        title: 'Привет!',
        text: 'Предлагаем тебе на пару минут отвлечься от учебных задачек, написания кода и вот этого вот всего. \n' +
            '\n' +
            'Запрыгивай в DeLorean и представь себя в роли сотрудника «Северсталь- инфокома». Но не простого стажера или джуна, а настоящего тимлида, который в данный момент ищет крутых и опытных коллег-кодеров для реализации самых разных задачек.'
    },
    {
        id: '2',
        title: null,
        text: 'Последние три года с момента твоей первой строчки кода для «Северстали» ' +
            'были сложными, но интересными. ' +
            'Помимо прокачки хардов пришлось выяснять, откуда в металлургической компании появилось столько IT-шников, ' +
            'и зачем они тут нужны. Спойлер: оказалось, что очень даже нужны! Например, в Воронеже и Череповце ' +
            'у «Северстали» есть целый IT-шный хаб ' +
            'в 2000 сотрудников: от девелоперов ' +
            'до разрабов на половине известных языков программирования. '
    },
    {
        id: '3',
        title: null,
        text:  'Представь ситуацию: на днях к тебе пришли коллеги с просьбой докрутить, пофиксить и — классическое — «сделать так, чтобы оно работало». В то же время, у тебя сейчас есть целых 3 горящих проекта, которые нужно реализовать ' +
            'как можно скорее и качественнее. ' +
            'Все твои коллеги, естественно, крутые спецы в своем деле, но для каждого ' +
            'из проектов нужно подобрать таких сотрудников, чьи скиллы и роли ' +
            'в команде лучше всего подходят ' +
            'под поставленные задачи. Итак, три проекта — одна попытка — один шанс получить премию за выполнение всех проектов в срок (шутка). Поехали?'
    },
]